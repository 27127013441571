import React from 'react';
import { Link } from "react-router-dom";

const PageNotFound = () => {
    const msgHTML = "Seriously! We don't know how you got to this page, but it doesn't exist!";
    const innerHTML = { __html: msgHTML};

    return (
        <div className="chapters-wrapper parent">
            <div className="middleDiv home-subtext text-width-center">
                <h2 className="home-title-text">PLAY SERIOUSLY!</h2>
                <div className="about-subtext" style = {{paddingTop:'30px', paddingBottom:'30px', textAlign:'center'}} dangerouslySetInnerHTML={innerHTML} />
                <Link to="/"><button className='button'> Home </button></Link>
            </div>
        </div>
  );
};

export default PageNotFound;