export const chaptersInfo = [{
    chapterNumber: 0,
    divID: 'ep0',
    name: 'Introduction',
    releaseDate: 'September XXth',
    descriptionHTML: `<p>Hello! I'm David Wallace. The goal of my teaching career has been to create learning environments that inspire the confidence to dream big &mdash; even audaciously. Please join me in this first episode as I introduce the ideas I'll share in <span class="text-italic">Play Seriously</span>. I hope the series will help you imagine, take action, and feel great satisfaction on whatever road your passions may take you.</p>`,
    videoLink: "https://www.youtube.com/embed/vWLmR3R575M?si=slZKkqfJtUFXrvhr",
  }, {
    chapterNumber: 1,
    divID: 'ep1',
    name: 'Everything is an Example',
    trailerDate: 'September 17',
    releaseDate: 'September 19',
    trailerHTML: `<p>It's <span class="text-italic">Play Seriously</span> Episode 1—the trailer. Here's a sneak peak of how I use the 2.009 build challenge to bring to life the principle <span class="text-italic">Everything is an example</span>.</p>`,
    descriptionHTML: `<p>Hello again! Episode 1 is here! We are what we practice, and we teach what we are. I believe everything that I do is an example. In this episode, a 2.009 activity called the build challenge is used to bring this principle to life. It's extreme, kind of crazy, and euphoric. What are we building? We're building an audacious willingness to love what we do. To be all-in for the extraordinary. Yes, it's fun! And we do it because Everything is an example.</p>`,
    videoLink: "https://youtu.be/ZBZ9DnsmcSc",
    trailerInfo: {
      playbackId: "yFIVL92no33iMG1lUP3pA02hg02HC2l4L1taSLM3xhfTw",
      title: "Episode 1 Trailer",
      metadata: {
          video_id: "ep1_trailer",
          video_title: "Episode 1 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#C941F2",
      posterURL: "https://playseriously.com/video_covers/Ep01_Mux_Thumb.jpg",
    },
  }, {
    chapterNumber: 2,
    divID: 'ep2',
    name: 'Have a Plan and Be Ready to Change it',
    trailerDate: 'September 24',
    releaseDate: 'September 26',
    trailerHTML: `<p>Yes, indeed! Have a plan and be ready to change it! What's that all about? The next episode is coming on Thursday! And until then, please watch the trailer to learn more!</p>`,
    descriptionHTML: `<p>Hello, Hello! In designing their product, every team member will invest &mdash; really believe &mdash; in ideas that end up on the cutting board. How do we make such hard decisions, choosing between options with truthfulness and kindness? How do we achieve our best possible outcome? Episode 2: Have a plan and be ready to change it!</p>`,
    videoLink: '',
    trailerInfo: {
      playbackId: "mwFS71xWzNHsjYcNlqxG5uh8anO8nm3g3dmjNq01k1yo",
      title: "Episode 2 Trailer",
      metadata: {
          video_id: "ep2_trailer",
          video_title: "Episode 2 Trailer",
          viewer_user_id: "ps_website"
      },
      accentColor: "#C941F2",
      posterURL: "https://playseriously.com/video_covers/Ep02_Mux_Thumb.jpg",
    },
  }, {
    chapterNumber: 3,
    divID: 'ep3',
    name: 'Make Students Stars Shine',
    trailerDate: 'October 1',
    releaseDate: 'October 3rd',
    trailerHTML: '',
    descriptionHTML: '',
    trailerLink: '',
    videoLink: '',
  }, {
    chapterNumber: 4,
    divID: 'ep4',
    name: 'Everyone has Something to Teach',
    trailerDate: 'October 9',
    releaseDate: 'October 11th',
    trailerHTML: '',
    descriptionHTML: '',
    trailerLink: '',
    videoLink: '',
  }, 
  {
    chapterNumber: 5,
    divID: 'ep5',
    name: 'Goals are Essential',
    trailerDate: 'October 15',
    releaseDate: 'October 17th',
    trailerHTML: '',
    descriptionHTML: '',
    trailerLink: '',
    videoLink: '',
  },  
  {
    chapterNumber: 6,
    divID: 'ep6',
    name: 'Play Seriously',
    trailerDate: 'October 22',
    releaseDate: 'October 24th',
    trailerHTML: '',
    descriptionHTML: '',
    trailerLink: '',
    videoLink: '',
  }, 
  {
    chapterNumber: 7,
    divID: 'ep7',
    name: 'The Euphoria of Growth',
    trailerDate: 'October29',
    releaseDate: 'October 31st',
    trailerHTML: '',
    descriptionHTML: '',
    trailerLink: '',
    videoLink: '',
  }];
