import React, { useState } from "react";
import { IoClose, IoShareSocialOutline, IoLogoFacebook, IoLogoInstagram, IoLogoTiktok} from "react-icons/io5";
import "./ShareMenu.css";
import { useMediaQuery } from 'react-responsive';
import { motion } from "framer-motion";

// TODO add viper and google gmail, signal
// add text messenging
// Instagram DM
// weibo and wechat
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  LineIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  WhatsappIcon,
  XIcon,
} from "react-share";

function ShareGrid() {
  const icons = [
    { id: 1, name: 'Facebook', icon: 
      <FacebookShareButton
      url={'http://playseriously.com'}
      quote={'Play Seriously! - a 7 part series, 1 year in production, 30 years in preparation'}
      hashtag={'#playseriously'}>
      <FacebookIcon size={"7vh"} round/>
      </FacebookShareButton>},

    { id: 2, name: 'Email', icon: 
    <EmailShareButton
      url={'http://playseriously.com'}
      subject={'Play Seriously!'}
      body={'Join Professor David Wallace as he explores thoughts on design and aspirations for excellence—told through the lens of MIT’s iconic course 2.009.'}>
      <EmailIcon size={"7vh"} round/>
    </EmailShareButton>},

    { id: 3, name: 'Instagram', icon: 
    <TwitterShareButton
          url={'http://playseriously.com'}
          title={'Play Seriously!'}
          hashtag={'#playseriously'}
        >
          <XIcon size={"7vh"}round />
        </TwitterShareButton>},

    { id: 4, name: 'LinkedIn', icon: 
        <WhatsappShareButton
          url={'http://playseriously.com'}
          title={'Play Seriously! 7 part video series. '}
          separator="30 years in preparation. One year in production. ">
          <WhatsappIcon size={"7vh"} round />
        </WhatsappShareButton> },
    
    { id: 5, name: 'LinkedIn', icon: 
        <LinkedinShareButton 
        url={'http://playseriously.com'} >
          <LinkedinIcon size={"7vh"} round />
        </LinkedinShareButton>},

    { id: 6, name: 'LinkedIn', icon: 
      <RedditShareButton
      url={'http://playseriously.com'}
      title={"Play Seriously!"}
      windowWidth={660}
      windowHeight={460}>
      <RedditIcon size={"7vh"} round />
    </RedditShareButton>},

    { id: 7, name: 'Line', icon:
      <LineShareButton 
        url={'http://playseriously.com'}
        title={"Play Seriously!"}>
        <LineIcon size={"7vh"} round />
        </LineShareButton>},

    { id: 8, name: 'Line', icon:
    <FacebookMessengerShareButton
    url={'http://playseriously.com'}>
    <FacebookMessengerIcon size={"7vh"}  round />
    </FacebookMessengerShareButton>},

    { id: 0, name: 'Line', icon:
    <TelegramShareButton
    url={'http://playseriously.com'}
    title={"Play Seriously!"}>
    <TelegramIcon size={"7vh"}  round />
    </TelegramShareButton>},
    
  ];

  return (
    <div className="icon-grid">
      {icons.map((icon) => (
        <div key={icon.id} className="icon-grid-item">
          {icon.icon}
        </div>
      ))}
    </div>
  );
}

function ShareMenu() {
  const [showShare, setShowShare] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleShare = () => {
    setShowShare(!showShare);
    console.log(showShare);
  };

  const variants = {
    closed: {x: '50%', opacity: 0},
    open:  {x: '-72%', opacity: 1},
  }

  const large = {
    closed: {x: '50%', opacity: 0},
    open:  {x: '-85%', opacity: 1},
  }


  if (isSmallScreen) {
    return (
      <div>
            <motion.div 
            className={`share__menu`}
              variants = {variants}
              initial = "closed"
              animate = {showShare? "open" : "closed"}
              transition = {{ type: "spring" }}
              // style={{
              // position: 'absolute'
              //  }}
              >
                <ShareGrid />
              <div className="nav__close" id="nav-toggle" onClick={toggleShare}>
                <IoClose size={32}/>
              </div>
            </motion.div>

            <motion.div 
            className="nav__toggle" id="nav-toggle" onClick={toggleShare}>
              <IoShareSocialOutline size={32}/>
            </motion.div>
      </div>
    );
  } else {
    return (<div> 

      <motion.div 
            className={`share__menu`}
              variants = {large}
              initial = "closed"
              animate = {showShare? "open" : "closed"}
              transition = {{ type: "spring" }}>
                <ShareGrid />
              <div className="nav__close" id="nav-toggle" onClick={toggleShare}>
                <IoClose size={32}/>
              </div>
            </motion.div>

      <div onClick={toggleShare}>
      Share
      </div>
    </div>);
  }
};

export default ShareMenu;