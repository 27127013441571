export const GoDeeperItems = {
    "ep0": [
        {
            id: "gd0_01",
            filename: "01_teachingPrinciplesList.webp",
            number: "1",
            blurb: "So, let's get started! ...",
            altText: "list of teaching epiphanies",
            descriptionHTML: `<p>So, let's get started! In the <span class="text-italic">About</span> section, I noted the 7 ideas that the <span class="text-italic">Play Seriously</span> series explores are drawn from a longer list of "teaching epiphanies" &mdash; things that I think I have learned about teaching. It's a list that I have added to year-on-year as part of my own teaching improvement process. Making the list helps me to crystalize and generalize ideas that I think are important in creating learning environments that inspire people to do their best.</p>

<p>For what it's worth, here's additional idea sound-bites from that list &mdash;in no particular order. There's a fair bit behind each statement, so if any pique your interest, it would be fun to discuss them with you on our forum. Just ask.</p>

<p>A main goal of <span class="text-italic">Play Seriously</span> is to bring the 7 chosen principles to life through real, in-class activites of students. Pretty much every class period in the 3 month term had activities that could be used to illustrate a given principle &mdash; so, working with Director Brian Cassin, I carefully picked a subset of class activities to follow. There were 12 filming days throughout the ~95 day 2023 term &mdash; what the budget could accomodate!</p>`
        },
        {
            id: "gd0_02a",
            filename: "02a_KresgeFinalPrototypeLaunch.webp",
            number: "2a",
            blurb: "Kresge Auditorium ...",
            altText: "Kresge Auditorium during 2.009 Prototype Launch",
            descriptionHTML: `<p>This is Kresge Auditorium on December 11, 2023 during the 2.009 final prototype launch. </p>`
        },
        {
            id: "gd0_02b",
            filename: "02b_excitementInKresge.webp",
            number: "2b",
            blurb: "An inspiring celebration ...",
            altText: "excitement in Kresge",
            descriptionHTML: `<p>An inspiring celebration for all, centered around what we do—design!</p>`
        },
        {
            id: "gd0_03",
            filename: "03_KresgeStudioSetup.webp",
            number: "3",
            blurb: "And this is Kresge ...",
            altText: "Kresge studio setup",
            descriptionHTML: `<p>And this is Kresge on March 21, 2024, converted to a studio for recording the explanatory narrative for the series. After recording the class in action during the fall of 2023, the narration was recorded in Kresge over 4 days in March. A busy 4 days.</p>`
        },
        {
            id: "gd0_04",
            filename: "04_cameras6k.webp",
            number: "4",
            blurb: "Wow, those are ...",
            altText: "cameras in Kresge",
            descriptionHTML: `<p>Wow, those are fancy cameras! For Play Seriously, our goal was to produce a high quality series with production that will stand the test of time. Everything—even the classroom footage—was recorded in high resolution 6K!</p>`
        },
        {
            id: "gd0_05",
            filename: "05_settingLightingUp.webp",
            number: "5",
            blurb: "Studio filming ...",
            altText: "lighting setup in Kresge",
            descriptionHTML: `<p>Studio filming involves a lot of test, sit around and wait, and test again—paying attention to and caring for the smallest of details. You'll hear more about that notion in Episode 1! Here, I'm feeling the thrill of posing while lighting adjustments are made.</p>`
        },
        {
            id: "gd0_06",
            filename: "06_scriptDiscussion.webp",
            number: "6",
            blurb: "And here we're ...",
            altText: "script discussion",
            descriptionHTML: `<p>And here we're noodling out a recording plan. Director Brian Cassin and I are thinking through the first part of the series introduction while props are being set up. The auditorium was heated, and Brian's a warm guy, so I'm not sure why he dressed like it's below freezing!</p>

<p>I had written a script to follow, but, like in life, many good things often come in the moment—when you are open to new opportunities.</p>`
        },
        {
            id: "gd0_07a",
            filename: "07a_introductionCrabStoryboard_p1.webp",
            number: "7a",
            blurb: "Anyone who has been in ...",
            altText: "introducing the crab storyboard",
            descriptionHTML: `<p>Anyone who has been in a class I teach will have heard about storyboards—a tool for designing presentations, product user experiences, and yes...films! A visual script. Here's part of a storyboard Brian made to think about the crab mobile scene.</p>`
        },
        {
            id: "gd0_7b",
            filename: "07b_introductionCrabStoryboard_p2.webp",
            number: "7b",
            blurb: "The final entry ...",
            altText: "crab storyboard panel",
            descriptionHTML: `<p>The final entry onto the stage.</p>`
        },
        {
            id: "gd0_08a",
            filename: "08a_crabInKillian.webp",
            number: "8a",
            blurb: "We filmed out-and-about ...",
            altText: "crab mobile in Killian Court",
            descriptionHTML: `<p>We filmed out-and-about campus with the crab mobile for the introduction sequence—here at the symbol of MIT, Killian Court.  It was a drizzly, wet day, but that didn't dampen our ability to play seriously. We were on task, focused, and having fun.</p>`
        },
        {
            id: "gd0_8b",
            filename: "08b_davidInKillian.webp",
            number: "8b",
            blurb: "Filming out-and-about ...",
            altText: "Prof Wallace in Killian",
            descriptionHTML: `<p>Filming out-and-about also involves a lot of test, stand around and wait, and test again!</p>`
        },
        {
            id: "gd0_09",
            filename: "09_crabBlocking.webp",
            number: "9",
            blurb: "Back in Kresge ...",
            altText: "crab mobile in Kresge",
            descriptionHTML: `<p>Back in Kresge, we're figuring out blocking for driving the crab mobile onto the stage.  When you watched the series introduction, did you notice that the crab was pretty wrinkly? It's quite visible on stage.  And no, the crab wasn't molting—the printed paper graphic was wrinkled because it was wet from filming outdoors in the rain. Wet paper expands a lot, plywood (the crab's substrate) not-so-much. Voila, wrinkles are born. Arg, such is life.</p>`
        },
        {
            id: "gd0_10",
            filename: "10_crewInAction.webp",
            number: "10",
            blurb: "Brian Cassin and Director ...",
            altText: "filming crew in action",
            descriptionHTML: `<p>Brian Cassin and Director of Photography Matthew Dorris and 1st Assistant Camera Asa Reed filming the grand entrance!</p>

<p>And for now, that's the introduction's backstory. Up next, Episode 1: Everything is an example.</p>`
        },
    ],
    "ep1": {
        preambleHTML: `<p>In episode 1, I used the 2.009 build challenge activity from fall 2023 to demonstrate the principle <span class="text-italic">everything is an example</span>, and to highlight how attending to every detail with care leads to something special &mdash; something that truly stands apart.  So, in this edition of <span class="text-italic">Back Stories</span>, I thought it would be interesting to dive into the build challenge itself.</p>

        <ol class="gd-abc-list">
            <li>What is the build challenge? Why do we do it? How do we do it?</li>
            <li>The 2023 build challenge. What was its story? What are the vehicle designs?</li>
            <li>Why does the build challenge change every year?</li>
        </ol>

<p>I'll also include videos and vehicle sketches for the build challenges that are discussed. And, if you have other thoughts or other questions, please join in our <a href="https://forum.playseriously.com" target="_blank">forum</a> and I'll respond as best that I can.</p>

<p>All right! Let's get started!</p>`,
        slideshowsList: [
            {
                slideshowId: "ep1_A",
                slideshowTitle: "A) What is the build challenge? Why do we do it? How do we do it?",
                slidesData: [
                    {
                        id: "gd1_A01",
                        number: "A1",
                        filename: "A01_theBuildChallenge.webp",
                        altText: "build challenge field",
                        blurb: "The build challenge! ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">What is the build challenge? Why do we do it? How do we do it?</p>
<p>The build challenge! As I said in episode 1, "It's extreme, it pushes limits, it's kind of crazy, it's euphoric. It's fun."</p>

<p>Fun indeed. Super important. But is that alone enough? My answer is... no! In teaching, I think it's important to think carefully about the lesson I'm trying to convey, and that every teaching action needs to somehow contribute to the mission of the learning experience. If something is included only because I think it's "cool", it's not much more than a "stunt" or "party trick", so to speak.</p> 

<p>I'm often asked why we don't promote the build challenge as a campus event. I don't because I want the students to know that it is for them and their learning experience alone. Nothing more.</p>`
                    },
                    {
                        id: "gd1_A02",
                        number: "A2",
                        filename: "A02_loveWhatWeDo.webp",
                        altText: "tired team",
                        blurb: "In the picture, we see ...",
                        descriptionHTML: `<p>In the picture, we see a tired, happy, and fulfilled Purple Team in the lab after a major prototyping milestone.</p> 

<p>I've said that my teaching goal is to build an audacious willingness to love what we do (which is product design), to learn how to work together, and to do-what-we-do well.</p>`
                    },
                    {
                        id: "gd1_A03",
                        number: "A3",
                        filename: "A03_ideateModelTest.webp",
                        altText: "ideate model test",
                        blurb: "The build challenge reflects ...",
                        descriptionHTML: `<p>The build challenge reflects this ideal; but, more specifically, I'm hoping to help students fully embrace the process of designing.</p>

                        <ul class="gd-no-bullet-list">
<li>Ideate &mdash; freely coming up with lots of ideas.</li>
<li>Model &mdash; building prototypes that represent ideas. <span class="text-italic">And</span></li> 
<li>Test! &mdash; using your models to experiment and learn.</li>
</ul>

<p>And then to start all over again, improving your ideas. Ideate. Model. Test!</p>`
                    },
                    {
                        id: "gd1_A04",
                        number: "A4",
                        filename: "A04_teamsAssembleVehicleInLab.webp",
                        altText: "team assembling vehicle",
                        blurb: "The build challenge is just ...",
                        descriptionHTML: `<p>The build challenge is just a low stakes practice run for this design cycle. Ideate. Model. Test! It runs in parallel to when student teams are executing their first design iteration for the real, "serious" products that they will conceive. This first iteration takes place over a three week cycle near the start of the term.</p>`
                    },
                    {
                        id: "gd1_A05",
                        number: "A5",
                        filename: "A05_vehicleConceptsInClass.webp",
                        altText: "students brainstorming in class",
                        blurb: "Day 1, Students Ideate! ...",
                        descriptionHTML: `<p class="text-italic">Day 1, Students Ideate!</p>

<p>Just as students are about to begin generating ideas for their products, we spend 30 minutes of class time practicing &mdash; individually ideating "silly vehicles" to complete a whimsical mission. That is, the build challenge's team relay-race mission!  The mission of the build challenge is usually an allegorical story connected to the year's "serious" product development theme. (I'll give you a specific example, in <span class="text-yellow">B4</span>, when I explain the story of the 2023 build challenge featured in episode 1.)</p>

<p>Then off the lab students go, working with their teams ideating for their real products! Meanwhile, behind the scenes, I typically work with former 2.009 student/teaching assistant/lecturer Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> sorting through all the student's ideas. Together we pick one interestingly ridiculous vehicle for each team. Then, I quickly flesh out each team's vehicle design, just enough to establish principles of propulsion. After discussing with the Pappalardo lab staff <a href="https://www.instagram.com/pappalardolab" target="_blank">@pappalardolab</a> and the class teaching assistants to get feedback, it's time to design the vehicles for real. I usually do this in a 24-30 hour marathon &mdash; sketching, sourcing parts, and working out details all at the same time.</p>`
                    },
                    {
                        id: "gd1_A06",
                        number: "A6",
                        filename: "A06_redTeamVehicleComponents.webp",
                        altText: "vehicle components",
                        blurb: "Day 10 ...",
                        descriptionHTML: `<p class="text-italic">Day 10</p>

<p>Pappalardo staff, teaching assistants, and a host of mentor volunteers spend a weekend together in the lab to make IKEA-style vehicle kits for each team. All together, about 30 kind folks help in the vehicle kit-building process. On this weekend, I also do the instruction sketches that the students will follow when they assemble their vehicles.</p>`
                    },
                    {
                        id: "gd1_A07",
                        number: "A7",
                        filename: "A07_purpleTeamBuildingVehicle.webp",
                        altText: "team building vehicle",
                        blurb: "Day 14, Model! ...",
                        descriptionHTML: `<p class="text-italic">Day 14, Model!</p>

<p>Just as student teams are about to start making exploratory models of their product ideas &mdash; very quick-and-dirty prototypes that we call <span class="text-italic">sketch models</span> &mdash; students spend a one hour class period in the lab building their vehicles from the kits that have been prepared for them. They get comfortable working in their team space, working with their tools, working with each other, and working on a timeline.</p>

<p>The vehicles provide examples of sketch-model-level prototyping; and, in making them, students are primed to work as a team on sketch models for their "serious" product ideas. Students are given this experience without distracting or taking time away from their primiary mission &mdash; to design a well-conceived product. It's just an hour of lecture time.</p>`
                    },
                    {
                        id: "gd1_A08a",
                        number: "A8a",
                        filename: "A08a_teamPresentsResearchAtReview.webp",
                        altText: "team presenting research",
                        blurb: "Day 18 ...",
                        descriptionHTML: `<p class="text-italic">Day 18</p>

<p>In their first major product development milestone, student teams present several competing product ideas under consideration. They explain their ideas and provide related market research.</p>`
                    },
                    {
                        id: "gd1_A08b",
                        number: "A8b",
                        filename: "A08b_teamDemonstratesSketchModelAtReview.webp",
                        altText: "team demonstrating sketch model",
                        blurb: "Day 18 (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 18 (continued)</p>
<p>The students also demonstrate ideas and key operational principles using simple sketch models. They discuss what they have learned in this initial ideate-model-test design cycle.</p>

<p>The goal is to explore many options at a high level to determine which product directions have the greatest potential.</p>`
                    },
                    {
                        id: "gd1_A09a",
                        number: "A9a",
                        filename: "A09a_buildChallengeDay.webp",
                        altText: "build challenge field",
                        blurb: "Day 19, Test! (AKA Build challenge ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! (AKA Build challenge day!)</p>

<p>In the lecture period following the product milestone review, it's time to test their whimsical vehicles in the mission of the build challenge. Yes. the build challenge. Live in Killian Court. It's a fast, furious and fun team bonding experience.</p>`
                    },
                    {
                        id: "gd1_A09b",
                        number: "A9b",
                        filename: "A09b_buildChallengeDay.webp",
                        altText: "team bonding at build challenge",
                        blurb: "Day 19, Test! (continued) ...",
                        descriptionHTML: `<p class="text-italic">Day 19, Test! Build challenge day! (continued)</p>
<p>Who cares who wins? It's a cathartic release to cap off the completion of their first <span class="text-italic">Ideate. Model. Test!</span> cycle. And, ironically, this silly challenge and its level of execution clearly says to the students "This is different. Our expectation is to do things that are exceptional, and together we can realize the extra-ordinary".</p>`
                    },
                    {
                        id: "gd1_A10",
                        number: "A10",
                        filename: "A10_finalNightOfSetup.webp",
                        altText: "night before build challenge",
                        blurb: "The playing field! ...",
                        descriptionHTML: `<p>And, oh yes, the build challenge playing field! The course in Killian Court that the students navigate with their vehicles, racing to complete the build challenge's mission. So far, we've only talked about the students' vehicles. Here it is, in the final setup stages, in the wee-hours of the night before the challenge.</p>`
                    },
                    {
                        id: "gd1_A11",
                        number: "A11",
                        filename: "A11_playingFieldLayout.webp",
                        altText: "build challenge playing field",
                        blurb: "In August, typically, I ...",
                        descriptionHTML: `<p>In August, typically, I work with Victor Hung <a href="https://www.instagram.com/poofytoo" target="_blank">@poofytoo</a> and the course teaching assistants to define the challenge for the year, and to outline a concept for the playing field. While Victor and his team work on the major playing field set pieces, class TAs and I work on all the bits needed to make the event sing. Team T-shirts, custom hard hats, making individualized plushies for students, custom designed chocolate coins, goodies, instructions, costumes, event logistics, photography, spirit awards, the 2.009 build challenge cup, videos &mdash; the list goes on-and-on. Everything is designed to be an example.</p>`
                    },
                    {
                        id: "gd1_A12",
                        number: "A12",
                        filename: "A12_playingFieldSetupTimelapse.webp",
                        altText: "build challenge playing field setup",
                        videoInfo: {
                            playbackId: "u4xr2I2GDSJscKSoaZSaxAOKxesEYp7iygc9TUhoqLU",
                            title: "Build Challenge Behind the Scenes Timelapse",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "gd1_A12_build_challenge_timelapse",
                                video_title: "Build Challenge Behind the Scenes Timelapse",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "And how do all of these ...",
                        descriptionHTML: `<p>And how do all of these details get thought about and then addressed with great care? As my advisor Woodie Flowers said, "play the movie in your head". I painstakingly experience the entire challenge and its preceeding weeks in my head, as detailed and step-by-step as I can, carefully noting what needs to happen and when. Then one must build a team and delegate, working with individuals who treat every element to which they contribute, big or seemingly very small, with ownership, careful thought, and the utmost of importance. Folks that "play the movie in their head", too.</p>

<p>The challenge is set up in Killian Court over one day and night &mdash; so it is a surprise for students when they arrive. All together, well over 1500 person hours (not including the student's time) and the help of nearly 100 people is needed to make the challenge happen. Thank you helpers over the years!</p>

<p>The challenge is designed to provide a practice <span class="text-italic">ideate. model. test!</span> learning experience that is meaningful, playful, and does not compete with or distract from the students' "serious" product development mission. Instead, it actually augments and sharpens their focus.</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_B",
                slideshowTitle: "B) The 2023 build challenge. What was its story? What are the vehicle designs?",
                slidesData: [
                    {
                        id: "gd1_B01",
                        number: "B1",
                        filename: "B01_wildAssociationMap.webp",
                        altText: "association map for Wild",
                        blurb: "Product development theme ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The 2023 build challenge. What was its story? What are the vehicle designs?</p>
<p>The product development theme in the fall was <span class="text-italic">Wild!</span> Exciting, untamed, and incredible. Products that give us the feeling of "wow, that was wild!". This association map for <span class="text-italic">wild</span> was provided to students as a launching pad to think for themselves about what types of products the theme of <span class="text-italic">wild</span> might inspire.</p>`
                    },
                    {
                        id: "gd1_B02",
                        number: "B2",
                        filename: "B02_mildyGuidedByMissChiefs.webp",
                        altText: "Mildy guided by Miss Chiefs",
                        blurb: "How did the build challenge ...",
                        descriptionHTML: `<p>How did the build challenge relate to <span class="text-italic">wild</span>? It's a story of transformation, how the character "Mildy", oppressed by the Bureau of Boredom (BoB), is transformed through the efforts of critters called Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B03",
                        number: "B3",
                        filename: "B03_WildyAndMissChiefs.webp",
                        altText: "Wildy and Miss Chiefs",
                        blurb: '"Mildy" transformed into "Wildy" ...',
                        descriptionHTML: `<p>"Mildy" transformed into "Wildy" through the power of the Miss Chiefs, in the wild!  "Wildy" &mdash; free to think and dream big!</p>

<p>I play Mildy and Wildy, and the students are the Miss Chiefs.</p>`
                    },
                    {
                        id: "gd1_B04",
                        number: "B4",
                        filename: "B04_2023BuildChallenge.webp",
                        altText: "2023 build challenge",
                        videoInfo: {
                            playbackId: "TZLo00OU65a01v02UHUNzYHi5PaEYebTU7SXM2adx024sGY",
                            title: "2023 Build Challenge - Into the Wild",
                            thumbnailTime: "5",
                            metadata: {
                                video_id: "gd1_B04_2023_build_challenge",
                                video_title: "2023 Build Challenge - Into the Wild",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "The build challenge video ...",
                        descriptionHTML: `<p>The build challenge video, prepared for showing at the 2023 2.009 final prototype launch event, will help you understand the flow of the challenge. At 1:15 in the video, there is a fun animation that explains the story of the build challenge mission. Enjoy!</p>`
                    },
                    {
                        id: "gd1_B05",
                        number: "B5",
                        filename: "B05_2023BuildVehicles.webp",
                        altText: "2023 build challenge vehicles",
                        blurb: "And, as promised, here are ...",
                        descriptionHTML: `<p>And, as promised, here are sketches of each team's vehicle for the 2023 build challenge. Download <a href="/back_stories/ep1/B05_2023BuildVehicles.pdf" target="_blank">PDF</a></p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_C",
                slideshowTitle: "C) The build challenge changes every year! But why?",
                slidesData: [
                    {
                        id: "gd1_C01",
                        number: "C1",
                        filename: "C01_2018dangerBuildChallenge.webp",
                        altText: "2018 build challenge",
                        blurb: "Changing allows the story ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">The build challenge changes every year! But why?</p>
                        <p>Changing allows the story of the challenge to reinforce each year's inspirational product development theme (which also changes every year). And this way students of each year know that it is <span class="text-italic">their</span> challenge. It is unique for them. This image is from 2018, when the year's design theme was <span class="text-italic">Danger</span>. The story of the build challenge pitted David Danger against Sir Safety, representing the fine balance of openly taking risks and also safe-guarding the well-being of those who we design for!</p>

<p>And, of course, by changing every year it lets us, the staff, try new things and improve, too! It's fun!</p>

<p>If the same challenge was dusted-off each year &mdash; which indeed is a lot less work &mdash; would we not be setting an example of being complacent, of standing still? Is that an example of excellence?</p>`
                    },
                    {
                        id: "gd1_C02",
                        number: "C2",
                        filename: "C02_2017buildChallenge.webp",
                        altText: "2017 build challenge",
                        videoInfo: {
                            playbackId: "27ygudoO9r1Qu7cBsrvkdpeizraUq4w6yxmLR00diiQA",
                            title: "2017 Build Challenge - Product Man",
                            thumbnailTime: "43",
                            metadata: {
                                video_id: "gd1_C02_2017_build_challenge",
                                video_title: "2017 Build Challenge - Product Man",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "A different build challenge ...",
                        descriptionHTML: `<p>Here's an example of a build challenge from a different year.</p>

<p>It's the fall of 2017. <span class="text-italic">Super</span> is the theme! It's the year of spontaneously combusting cell phones and the Juicero! Students use their super powers to rescue Superhero Product Man and defeat Dr. Defective, battling bad design!</p>`
                    },
                    {
                        id: "gd1_C03",
                        number: "C3",
                        filename: "C03_2017BuildVehicles.webp",
                        altText: "2017 build challenge vehicles",
                        blurb: "Sketches of 2017 build ...",
                        descriptionHTML: `<p>Here are sketches of 2017 build challenge vehicles. Download <a href="/back_stories/ep1/C03_2017BuildVehicles.pdf" target="_blank">PDF</a></p>

<p>If you'd like to see more, you can find many years' build challenge videos in the <a href="http://designed.mit.edu/new/index.html" target="_blank">2.009 gallery</a> (look for the build challenge links).</p>`
                    },
                ]
            },
            {
                slideshowId: "ep1_D",
                slideshowTitle: "D) Ideate! Model! Test! Repeat.",
                slidesData: [
                    {
                        id: "gd1_D01",
                        number: "D1",
                        filename: "D01_2023ideateModelTestRepeat.webp",
                        altText: "ideate model test repeat",
                        videoInfo: {
                            playbackId: "Z5bZp6c01NZCObpsqdxz651ZqS7xv9eb18dOvgOKINZ4",
                            title: "Ideate! Model! Test! Repeat.",
                            thumbnailTime: "38",
                            metadata: {
                                video_id: "gd1_D01_2023_IMTR_video",
                                video_title: "Ideate! Model! Test! Repeat.",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "What's the mantra? ...",
                        descriptionHTML: `<p class="gd-slideshow-card-title">Ideate! Model! Test! Repeat.</p>

<p>And finally, what's the build challenge design mantra? <span class="text-italic">Ideate! Model! Test! Repeat.</span> Join Mildy and Wildy in the animated musical!</p>`
                    },
                ]
            },
        ]
    },


/*
    "ep2": {
        preambleHTML: ``,
        slideshowsList: [
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
            {
                slideshowId: "",
                slideshowTitle: "",
                slidesData: [
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                    {
                        id: "gd1_xx",
                        number: "xx",
                        filename: "xx_.webp",
                        altText: "",
                        videoInfo: {
                            playbackId: "",
                            title: "",
                            thumbnailTime: "0",
                            metadata: {
                                video_id: "",
                                video_title: "",
                                viewer_user_id: "ps_website"
                            },
                        },
                        blurb: "",
                        descriptionHTML: `<p></p>`
                    },
                ]
            },
        ]
    },
*/

}
