import React, { useState } from 'react';
import { Link, useParams } from "react-router-dom";
import "./GoDeeper.css";
import { GoDeeperItems } from './GoDeeperData';
import ExpandableImage from '../Components/ExpandableImage/ExpandableImage';
import GoDeeperCarousel from '../Components/GoDeeperCarousel/GoDeeperCarousel';


function SlideshowImagePreview({episodeId, slideData, clickHandler}) {
    return (
        <div className="gd-img-preview-container" onClick={() => clickHandler(slideData.id)}>
        <img
          className="gd-img-preview"
          src={'/back_stories/'+episodeId+'/'+slideData.filename}
          alt={slideData.altText}
        />
        <div className="gd-text-overlay">
          <div className="gd-img-preview-id">{ slideData.number }</div>
          <div className="gd-img-preview-blurb">{ slideData.blurb }</div>
        </div>
      </div>
    );
}

function SlideshowSection({episodeId, slideshowData, clickHandler}) {
    const ss_imgs = slideshowData.slidesData.map(slideData =>
        <SlideshowImagePreview key={episodeId+'_'+slideData.number} episodeId={episodeId} slideData={slideData} clickHandler={clickHandler} />
    );

    return (
        <div className="gd-slideshow-section" id={slideshowData.slideshowId}>
            <div className="gd-slideshow-section-title">{ slideshowData.slideshowTitle }</div>
            <div className="gd-img-container">
                { ss_imgs }
            </div>
        </div>
    );
}

const GoDeeper = () => {
    const { episodeId } = useParams();
    const [currentCardId, setCurrentCardId] = useState("");

    const ep_info = GoDeeperItems[episodeId] || '';
    if (ep_info!=='') { // valid episode specified
        if (episodeId === "ep0") { // original format
            const filebase = '/go_deeper/'+episodeId+'/';
            const ep_imgs = ep_info.map(info =>
                <ExpandableImage key={episodeId+'_'+info.number} filePath={filebase+info.filename} altText={info.altText} panelId={info.number} blurb={info.blurb} descriptionHTML={info.descriptionHTML} />
            );
            let sectionClasses = "go-deeper-section gd-"+episodeId;
            let sectionId = "gd-"+episodeId;
            let returnAnchor = episodeId==="ep0" ? "/#introduction" : "/#"+episodeId;
            let episodeName = episodeId==="ep0" ? "Introduction" : "Episode "+episodeId.slice(-1);
            return (
                <div className="chapters-wrapper parent">
                    <div className="middle-div home-subtext">
                        <h2 className="gd-title-text text-width-center">Back Stories!</h2>    
                        <div className={sectionClasses} id={sectionId}>
                            <h3 className="gd-section-title">Back Stories for <span style={{ color: "var(--yellow)"}}>{episodeName}</span></h3>
        
                            <div className="gd-img-container">
                                { ep_imgs }
                            </div>
                            <div className="gd-return-to-episode-container"><Link to={returnAnchor}><button className='gd-button'>Return to {episodeName}</button></Link></div>
                        </div>
                        <p>&nbsp;</p>
                    </div>
                </div>
            );
        } else { // new format
            let sectionClasses = "go-deeper-section gd-"+episodeId;
            let sectionId = "gd-"+episodeId;
            let returnAnchor = episodeId==="ep0" ? "/#introduction" : "/#"+episodeId;
            let episodeName = episodeId==="ep0" ? "Introduction" : "Episode "+episodeId.slice(-1);
            const carouselId = "carousel-"+episodeId;

            function onClickHandler(cardId) {
                console.log("click by:"+cardId);
                setCurrentCardId(cardId);
                document.getElementById(carouselId).show();
            }

            const slideshow_sections = ep_info.slideshowsList.map(slideshowData =>
                <SlideshowSection key={slideshowData.slideshowId} episodeId={episodeId} slideshowData={slideshowData} clickHandler={onClickHandler} />
            );

            return (
                <div className="chapters-wrapper parent">
                    <div className="middle-div home-subtext">
                        <h2 className="gd-title-text text-width-center">Back Stories!</h2>
                        <GoDeeperCarousel episodeId={episodeId} slideshowsList={ep_info.slideshowsList} requestedCardId={currentCardId} />
                        <div className={sectionClasses} id={sectionId}>
                            <h3 className="gd-section-title">Back Stories for <span style={{ color: "var(--yellow)"}}>{episodeName}</span></h3>
                            <div className="gd-preamble" dangerouslySetInnerHTML={{ __html: ep_info.preambleHTML }}></div>
                            { slideshow_sections}
                            <div className="gd-return-to-episode-container"><Link to={returnAnchor}><button className='gd-button'>Return to {episodeName}</button></Link></div>
                        </div>
                        <p>&nbsp;</p>
                    </div>
                </div>
            );
        }
    } else { // default index
        return (
            <div className="chapters-wrapper parent">
                <div className="middle-div home-subtext">
                    <h2 className="gd-title-text text-width-center">Back Stories!</h2>
                    <div className="go-deeper-section" id="gd-preamble">
                        <p>
                        Welcome! In <span className="text-italic">Back Stories</span> I'll share additional context and behind-the-scenes goodies for each episode. Sometimes, there might also be activities or mini-projects. Every episode will have it's own <span className="text-italic">Back Stories</span> section!
                        </p>
                        <div className="gd-episode-list-item-container">
                            <img className="gd-episode-cover-img" src="/video_covers/Ep00_YouTubeThumb.jpg" alt="Introduction cover" /><Link class="gd-episode-title" to="/BackStories/ep0">Back Stories for Introduction</Link>
                        </div>
                        <div className="gd-episode-list-item-container">
                            <img className="gd-episode-cover-img" src="/video_covers/Ep01_YouTubeThumb.jpg" alt="Episode 1 cover" /><Link class="gd-episode-title" to="/BackStories/ep1">Back Stories for Episode 1</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default GoDeeper