import './Scrollbar.css'
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useScroll, motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

function ScrollToTop() {
    const { hash } = useLocation();
  
    useEffect(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [hash]);
  
    return null;
}

const scrollbnt = {
  hover: {scale: 1.5},
	tap: {scale: 0.9},
  show: {
    transition: { type: "spring", stiffness: 400, damping: 10 }
  }
}

const Scrollbar = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const location = useLocation();
    console.log(location.pathname);

    const scrollableContainer = React.createRef();
    const { y } = useScroll(scrollableContainer);

    const sections = [
      { id: 'home', top: 0, bottom: 100 },
      { id: 'introduction', top: 100, bottom: 200 },
      { id: 'ep1', top: 1000, bottom: 1500 },
      { id: 'ep2', top: 1500, bottom: 2000 },
    ];
    const [activeSection, setActiveSection] = useState(null);

    // useEffect(() => {
    //   const currentSection = sections.find((section) => y >= section.top && y <= section.bottom);
    //   if (currentSection) {
    //     setActiveSection(currentSection.id);
    //   }
    //   console.log(y);
    // }, [y, sections]);

    const { scrollY } = useScroll();
    useEffect(() => {
      console.log(`Scroll position: y=${y}`);
    }, [y]);

    if (location.pathname === '/') {
      return(
        <div class="nav-panel">
            <nav>
                <ScrollToTop />    
                <ul>

                <li>
                    <a href="#home">
                    <motion.div class="nav-btn square"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show"
                      style={{
                        opacity: location.hash === '#home' ? 0.3 : 1,
                        cursor: location.hash === '#home' ? 'not-allowed' : 'pointer',
                      }}>
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#introduction">
                    <motion.div class="nav-btn circle intro-color"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show"
                      style={{
                        opacity: location.hash === '#introduction' ? 0.3 : 1,
                        cursor: location.hash === '#introduction' ? 'not-allowed' : 'pointer',
                      }}>
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep1">
                    <motion.div class="nav-btn circle ep1-color"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show"
                      style={{
                        opacity: location.hash === '#ep1' ? 0.3 : 1,
                        cursor: location.hash === '#ep1' ? 'not-allowed' : 'pointer',
                      }}>
                      </motion.div>
                    </a>
                </li>

                <li>
                <a href="#ep2">
                    <motion.div class="nav-btn circle ep2-color"
                      variants={scrollbnt}
                      whileHover="hover"
                      whileTap="tap"
                      transition= "show"
                      style={{
                        opacity: location.hash === '#ep2' ? 0.3 : 1,
                        cursor: location.hash === '#ep2' ? 'not-allowed' : 'pointer',
                      }}>
                      </motion.div>
                    </a>
                </li>

                <li><div class="nav-btn circle-blank"></div></li>
                <li><div class="nav-btn circle-blank"></div></li>
                <li><div class="nav-btn circle-blank"></div></li>
                <li><div class="nav-btn circle-blank"></div></li>
                <li><div class="nav-btn circle-blank"></div></li>
                </ul>
            </nav>
        </div>
      );
    } else{
      return null;
    }
}

export default Scrollbar;
