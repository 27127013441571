import React from "react";
import "./ExpandableImage.css";

export default function ExpandableImage({key, filePath, altText, panelId, blurb, descriptionHTML}) {
  let dialogId = 'dialog-'+panelId;

  function openDialog() {
    document.getElementById(dialogId).showModal();
  };

  function closeDialog() {
    document.getElementById(dialogId).close();
  };
    
  return (
    <div className="ei-img-component">
      <dialog id={dialogId}
        className="img-popup"
      >
        <span class="ei-dialog-close" onClick={closeDialog}>&times;</span>
        <div className="ei-figure-container">
          <div>
            <img
                className="ei-large"
                src={filePath}
                alt={altText}
              />
          </div>
          <div className="ei-caption-text" dangerouslySetInnerHTML={{ __html: descriptionHTML}}></div>
        </div>
      </dialog>
      <div className="ei-small-img-container" onClick={openDialog}>
        <img
          className="ei-small"
          src={filePath}
          alt={altText}
        />
        <div className="ei-text-overlay">
          <div className="ei-panel-id"><span className="ei-panel-id-text">{ panelId }</span></div>
          <div className="ei-panel-blurb">{ blurb }</div>
        </div>
      </div>
    </div>
  );
}
