import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoClose, IoMenu} from "react-icons/io5";
import { useMediaQuery } from 'react-responsive';
import { motion } from "framer-motion";
import "./HomeMenu.css";

const HomeMenu = () => {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const [showMenu, setShowMenu] = useState(false);
    const toggleMenu = () => {
      setShowMenu(!showMenu);
    };
  
    const closeMenuOnMobile = () => {
      if (window.innerWidth <= 1150) {
        setShowMenu(false);
      }
    };

    const variants = {
      closed: {x: '-10%', opacity: 0.5},
      open:  {x: '113%', opacity: 1},
    }
  
    if (isSmallScreen) {
      return (
      <div>         
        <motion.div 
        className={`menu_wrapper`}
          variants = {variants}
          initial = "closed"
          animate = {showMenu? "open" : "closed"}
          transition = {{ type: "spring" }}>

          <div class="menu_text" >
                <Link to="/" class="menu_link"
                  onClick={closeMenuOnMobile}>
                  <p class="menu_link">Home</p>
                  </Link>

                <Link to="/BackStories" class="menu_link"
                  onClick={closeMenuOnMobile}>
                  <p class="menu_link">Back Stories</p>
                  </Link>

                <Link to="https://forum.playseriously.com" class="menu_link" target="_blank"
                  onClick={closeMenuOnMobile}>
                  <p class="menu_link">Forum</p>
                  </Link>
                
                <Link
                  to="/about" class="menu_link"
                  onClick={closeMenuOnMobile}>
                  <p class="menu_link">About</p>
                  </Link>
          </div>

          <div className="menu__close" id="nav-toggle" onClick={toggleMenu}>
              <IoClose size={32}/>
          </div>
        </motion.div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
            <IoMenu size={32}/>
        </div>
    </div>
    );}
    else{
    return (<div> 

    </div>)
    }
  };
  
  export default HomeMenu;