//import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar.js';
import Scrollbar from './Components/Scrollbar/Scrollbar.js';
import {React, useState} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import GoDeeper from './Pages/GoDeeper.jsx';
import Confirmation from './Pages/Confirmation.jsx';
import PageNotFound from './Pages/PageNotFound.jsx';
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";


function App() {
  let featuredEpisode = "ep1"; 

  return (
    <div>
    <BrowserRouter>
      <Navbar />
      <Scrollbar />
      <Routes>
        <Route path="/" exact element={<Home featuredEpisode="" />} />
        <Route path="/Ep" exact element={<Home featuredEpisode={featuredEpisode} />} />
        <Route path="/About" element={<About />} />
        <Route path="/GoDeeper" element={<GoDeeper />} />
        <Route path="/GoDeeper/:episodeId" element={<GoDeeper />} />
        <Route path="/BackStories" element={<GoDeeper />} />
        <Route path="/BackStories/:episodeId" element={<GoDeeper />} />
        <Route path="/Confirmation/:msgType" element={<Confirmation />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}



export default App;
