import React from 'react';
import { Link, useParams } from "react-router-dom";
import { defaultMsg, messagesInfo } from "./ConfirmationData.js";

const Confirmation = () => {
    const { msgType } = useParams();

    const innerHTML = { __html: messagesInfo[msgType] || defaultMsg };
    let styleDict = {}

    if (msgType in messagesInfo) {
        styleDict = {
            paddingTop:'30px',
            paddingBottom:'30px',
            textAlign:'justify'
        };
    } else {
        styleDict = {
            paddingTop:'30px',
            paddingBottom:'30px',
            textAlign:'center'
        };
    }

    return (
        <div className="chapters-wrapper parent">
            <div className="middleDiv home-subtext text-width-center">
                <h2 className="home-title-text">PLAY SERIOUSLY!</h2>
                <div className="about-subtext" style = {styleDict} dangerouslySetInnerHTML={innerHTML} />
                <Link to="/"><button className='button'> Home </button></Link>
            </div>
        </div>
  );
};

export default Confirmation;