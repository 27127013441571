import React from 'react';
import { Link } from "react-router-dom";



const About = () => {

    return (
    <div class="chapters-wrapper parent">
        <div class="middleDiv home-subtext ">
            <h2 class="home-title-text text-width-center" id="home">PLAY SERIOUSLY!</h2>
            <p class="about-subtext" style={{fontSize: '.8em'}}>
            <span style={{fontWeight: 600}}>Play se-ri-ous-ly</span>: /plā/ /ˈsirēəslē/<br></br>
                <span style={{ fontStyle: 'italic'}}>To engage in a serious activity with earnest intent, not lightly or superficially, with a playful spirit of freedom and enjoyment.</span>
            </p>
            <br></br> 
            <div class="about-subtext">
            <p>Over the past 30 years, Professor David Wallace (that’s me!) has continuously crafted and evolved his teaching in the <a href="https://2023.2009.mit.edu/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', color: 'var(--yellow)' }}>MIT product design class 2.009</a> to create an environment where students feel fulfillment and joy in the challenge of the pursuit of excellence. An environment where we learn and believe that we can contribute in ways we didn't imagine that we could. To be confident <span style={{ fontStyle: 'italic'}}>and</span> have the  humility to "do the work".  To tackle challenges with zeal. As a result, 2.009 has become an iconic course at MIT.</p>
            <br></br>
            <p>As part of my improvement process, over the years I wrote down and refined key ideas, or principles, that have guided me on my teaching journey. It’s a long list and, in <span style={{ fontStyle: 'italic', fontWeight: 600}}>Play Seriously</span>, I share 7 ideas from this list. Each is explored in a roughly 5 minute episode. Narrated by myself, each principle is brought to life through the experiences of students in 2.009. </p>
            <br></br>
            <p><span style={{ fontStyle: 'italic', fontWeight: 600}}>Play Seriously</span> was filmed with the fall 2023 2.009 class, and produced over the following 9 months. For each idea, you can also <Link to="/GoDeeper" style={{ color: 'var(--yellow)' }}>Go Deeper</Link> with behind-the-scenes photos, enrichment materials and newsletters &mdash; or discuss questions with me in our <Link to="https://forum.playseriously.com" target="_blank" style={{ color: 'var(--yellow)' }}>forum</Link>. I hope the series helps you dream big and take action. I hope the series will help you to Play Seriously. </p>
            <br></br>
            <p style={{ fontStyle: 'italic', fontWeight: 700, textAlign: 'center'}}>To my teachers, colleagues, volunteers, students, friends, and sponsors who have helped to inspire and encourage this teaching philosophy throughout my career. Thank you.</p>
            </div>
            <br></br>

        <div class="credit text-width-center" id = "fullcredit">
            <hr style={{ border: '1px solid #ccc'}} />
            <p style={{fontWeight: 600, marginTop: 100}}> Full Credits</p>
            <br></br>
            <p class="credit-title"> Funded by</p>
            <p class="credit-detail">A. Neil and Jane Pappalardo Mechanical 
            Engineering Curriculum Development Fund</p>
            <br></br>
            <p class="credit-title">Featuring</p>
            <p class="credit-detail">Professor David Wallace <br></br>Students and Staff of 2.009</p>
            <br></br>
            <p class="credit-title">Director</p>
            <p class="credit-detail">Brian Cassin</p>
            <br></br>
            <p class="credit-title">Producer</p>
            <p class="credit-detail">Carley Byers</p>
            <br></br>
            <p class="credit-title">Executive Producer</p>
            <p class="credit-detail">Joan Cassin</p>
            <br></br>
            <p class="credit-title">Field Producer</p>
            <p class="credit-detail">Kim DiVincenzo</p>
            <br></br>
            <p class="credit-title">Director of Photography</p>
            <p class="credit-detail">Matthew Dorris<br></br>Adam Mikaelian</p>
            <br></br>
            <p class="credit-title">Editor</p>
            <p class="credit-detail">Brian Cassin<br></br>Evan Griffin</p>
            <br></br>
            <p class="credit-title">Props</p>
            <p class="credit-detail">Kat Liberman<br></br>Nick Anastasia<br></br>Renata Caines<br></br>Nathan Phipps</p>
            <br></br>
            <p class="credit-title">Camera Operator</p>
            <p class="credit-detail">Jake Davidson<br></br>Chris DeSanty<br></br>John Kopec</p>
            <br></br>
            <p class="credit-title">First Assistant Camera</p>
            <p class="credit-detail">Asa Reed</p>
            <br></br>
            <p class="credit-title">Second Assistant Camera</p>
            <p class="credit-detail">Keenan Kimetto</p>
            <br></br>
            <p class="credit-title">Gaffer</p>
            <p class="credit-detail">Kellon Haynes<br></br>Alec Roy</p>
            <br></br>
            <p class="credit-title">Key Grip</p>
            <p class="credit-detail">Dirt Haehnel</p>
            <br></br>
            <p class="credit-title">Swing</p>
            <p class="credit-detail">Josiah Bedrosian</p>
            <br></br>
            <p class="credit-title">G&E Additional</p>
            <p class="credit-detail">Tyler Wasson</p>
            <br></br>
            <p class="credit-title">Production Sound</p>
            <p class="credit-detail">LaRon Cooper<br></br>Shane Bronson</p>
            <br></br>
            <p class="credit-title">Animation</p>
            <p class="credit-detail">Connie Cheng<br></br>Aleksandra "Zutto" Kyshtymova</p>
            <br></br>
            <p class="credit-title">Visual Effects</p>
            <p class="credit-detail">Adrian Garcia</p>
            <br></br>
            <p class="credit-title">Colorist</p>
            <p class="credit-detail">Evan Griffin</p>
            <br></br>
            <p class="credit-title">Audio Mix</p>
            <p class="credit-detail">Billy Wirasnik</p>
            <br></br>
            <p class="credit-title">Set Photographer</p>
            <p class="credit-detail">Danny Ebersole</p>
            <br></br>
            <p class="credit-title">Assistant Editor</p>
            <p class="credit-detail">TJ Wasserman</p>
            <br></br>
            <p class="credit-title">Production Assistant</p>
            <p class="credit-detail">Briana Maltez</p>
            <br></br>
            <p class="credit-title">Original Song</p>
            <p class="credit-detail">Sam Watkinson</p>
            <br></br>
            <p class="credit-title">Music</p>
            <p class="credit-detail">Musicbed</p>   
            <br></br>
            <Link to="/"><button className='button'> Home </button></Link>
        </div>
        </div>
    </div>

  );
};

export default About;