export const defaultMsg = "Seriously! We don't know how you got to this page, but it doesn't exist!";

export const messagesInfo = {
    /* key: html message */

    /* opt-out of Play Seriously notifications */
    'nPS': "We received your request not to be emailed about the <span style='font-style: italic;'>Play Seriously</span> series again.",

    /* opt-in to Play Seriously mail list */
    'yPS': "We've added you to our mail list. Thanks! We will let you know when we post trailers and new episodes.",

    /* filled out maillist form, still need to confirm */
    'cEM': "Thank you for your interest in joining our mail list! Please click on the button in your confirmation email to complete the process of joining the list."
}